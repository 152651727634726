import * as React from "react"
import styled from 'styled-components'
import SEO from '../components/SEO.js'
import Navbar from '../components/Navbar.js'
import About from '../components/nosotros/About.js'
import Footer from '../components/Footer.js'
import Header from '../components/Header.js'
import Backdrop from '../images/banners/nosotros.jpg'

const AboutPage = () => {
  return (
    <Container>
      <SEO title="Nosotros" description="Nosotros"/>
      <Header title="Sobre Nosotros" image={Backdrop} subtitle="Conformamos un equipo con amplia experiencia en gestión clínica y manejo de técnicas propias del área."/>
      <About/>
      <Footer/>
      <Navbar/>
    </Container>
  )
}

export default AboutPage

const Container = styled.div`
  font-family: Century Gothic,CenturyGothic,sans-serif;
  margin: -8px;
  padding-top: 93px;
`
