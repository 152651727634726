import * as React from "react"
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components'
import hugo from '../../images/personel/hugo.jpg'
import haydee from '../../images/personel/haydee.jpg'
import pamela from '../../images/personel/pamela.jpg'
import carolina from '../../images/personel/carolina.jpg'

const About = () => {
  return (
    <Container>
      <Grid direction="row" container justify="center" alignItems="center">
         <Grid direction="column" container item justify="center" alignItems="center" md={6} xs={12} style={{marginTop: "30px", marginBottom: "30px"}}>
          <Card photo={hugo} name="Hugo Arrey Palacios" description="Director Técnico, Tecnólogo Medico, Magister en Ciencias"/>
         </Grid>
         <Grid direction="column" container item justify="center" alignItems="center" md={6} xs={12} style={{marginTop: "30px", marginBottom: "30px"}}>
          <Card photo={haydee} name="Haydee Argel Linco" description="Técnico Paramédico Laboratorio Clínico"/>
        </Grid>
      </Grid>
      <Grid direction="row" container justify="center" alignItems="center">
         <Grid direction="column" container item justify="center" alignItems="center" md={6} xs={12} style={{marginBottom: "30px"}}>
          <Card photo={carolina} name="Carolina Briones Lobos" description="Técnico Paramédico Enfermería"/>
         </Grid>
         <Grid direction="column" container item justify="center" alignItems="center" md={6} xs={12} style={{marginBottom: "30px"}}>
          <Card photo={pamela} name="Pamela Rombado Roman" description="Secretaria Ejecutiva"/>
        </Grid>
      </Grid>
    </Container>
  )
}

const Card = (props) => {
  return (
    <Grid container direction="row" alignItems="center">
       <Grid direction="column" container item alignItems="center" sm={6}>
          <Photo src={props.photo} alt='' style={{borderRadius:"100px", width:"150px", height:"150px", marginTop: "2px", marginLeft: "2px"}}/>
       </Grid>
       <TextGrid direction="column" container item sm={6}>
         <div style={{fontWeight: "bold"}}>
           {props.name}
         </div>
         <div>
           {props.description}
         </div>
       </TextGrid>
    </Grid>
  )
}

const Container = styled.div`
  margin-bottom: 50px;
  margin-top: 50px;
  margin-left: 150px;
  margin-right: 150px;

  @media only screen and (max-width: 1200px) {
    margin-left: 50px;
    margin-right: 50px;
 }

 @media only screen and (max-width: 959px) {
   margin-left: 150px;
   margin-right: 150px;
 }

 @media only screen and (max-width: 850px) {
   margin-left: 100px;
   margin-right: 100px;
 }
`

const Photo = styled.img`
  width: 150px;
  object-fit: cover;
`

const TextGrid = styled(Grid)`
  padding-right: 20px;

  @media only screen and (max-width: 599px) {
    margin-top: 20px;
    text-align: center;
    padding-right: 0px;
  }
`

export default About
